import React from 'react'
import { Helmet } from 'react-helmet';
const Contact = () => {
  return (
    <div className='contact-us'>
         <Helmet>
                <title>Contact Us - Binary Systems</title>
                <meta name="description" content="Get in touch with Binary Systems for any queries. Find our contact details including office address, phone number, and email." />
                <meta name="keywords" content="Contact, Binary Systems, office address, phone number, email" />
            </Helmet>
        <h2>Contact Us</h2>
        <h3>
        Binary Systems
        </h3>
        <h3>Registered Office Address:</h3>
        <p>
        H-903, Sitapura Industrial Area, Phase-3, Jaipur – 302022 (Rajasthan)
        </p>
        <h3>
        Phone No:
        </h3>
        <p>
        +91 141 4023144
        </p>
        <h3>
        Email:
        </h3>
        <p>
        binarysystems125@gmail.com
        </p>
      
    </div>
  )
}

export default Contact
