import React from 'react';
import { Helmet } from 'react-helmet';
import '../../Styles/pages.css';

const AboutUs = () => {
  return (
    <div className='about-us'>
      <Helmet>
        <title>About Us - MP Nurses Registration Council </title>
        <meta name="description" content="Learn more about Binary Systems, a firm specializing in bulk data entry, scanning, data processing, and manufacturing of smart cards, RFID cards, and security printers. Based in Jaipur, Rajasthan." />
        <meta name="keywords" content="Binary Systems, data entry, scanning, data processing, photo imaging, bar coding, PVC cards, smart cards, RFID cards, security printers, Jaipur" />
      </Helmet>
      <h2>About Us</h2>
      <p>
        Binary Systems (GST No. 08ABEPA9306L1ZN) and (PAN No. ABEPA9306L) is a proprietorship firm with its registered office at H-903, Sitapura Industrial Area, Phase-3, Jaipur – 302022 (Rajasthan).
      </p>
      <p>
        BINARY offers specialized services for bulk data entry and scanning, data processing, photo imaging, bar/QR coding, as well as the manufacturing of PVC cards, including smart cards, RFID cards, and security printers. We provide complete solutions for both government and private sector organizations. Work starts immediately on assignment and is completed within the stipulated time schedule, adhering to strict quality control norms to maintain a high level of accuracy.
      </p>
      <p>
        BINARY is equipped with the latest computer hardware with sufficient backup and data transmission facilities. The entire network is supported by self-designed and developed software to suit job requirements. Our printing facilities are notable for speed and quality, along with infrastructure to provide bar coding according to customer needs. We have our own data entry section, which minimizes dependency on external agencies, reducing costs and benefiting our customers.
      </p>
    </div>
  );
}

export default AboutUs;
