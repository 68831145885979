import React from 'react'
import { Helmet } from 'react-helmet';
const Conditions = () => {
  return (
    <div className='terms-conditions'>
        <Helmet>
        <title>Terms & Conditions - MP Nurses Registration Council</title>
        <meta name="description" content="Read the terms and conditions for using the MP Nurses Registration Council website, including payment options, transaction confirmations, and usage provisions." />
        <meta name="keywords" content="Terms and Conditions, MP Nurses Registration Council, payment options, online payments, transaction confirmation, legal terms" />
      </Helmet>
        <h2>
        Terms & Conditions
        </h2>
        <div className='condition-content'>
            <>
            <h3>
            1. Payment Options
            </h3>
            <h3>
            Online Card Payments
            </h3>
            <p>
            Visa, Master and American express Card payments are processed through an online payment gateway system. You need not worry about your card information falling into the wrong hands because your bank will authorize the card transaction directly without any information passing through us. In approximately 25-30 seconds (depending on your internet connection) your bank will issue, using the online payment gateway, an authorization code and confirmation of completion of transaction. mpnrconline.org, as a Let's Encrypt Site, uses the latest 128 bit encryption technology and other sophisticated methods to protect your credit card information. You can book your product using SSL encryption (the internet standard for secure transactions). In fact, transacting online with a credit card at the Website is even safer than using a credit card at a restaurant because we do not retain your credit card information. You can be assured that mpnrconline.org offers you the highest standards of security currently available on the internet so as to ensure that your shopping experience is private, safe and secure. 
            </p>
            <p>
            If the payment on the credit card is declined for some reason, alternate payment instructions must be received by mpnrconline.org 72 hours prior to the time of departure, else, the order is liable to be cancelled. mpnrconline.org charges a service fee as per instructions by MPNRC.
            </p>
            <h3>Internet Banking</h3>
            
            <p>If you have an account with any of the banks in India , then you can pay for your fees through respective bank's net banking options and the amount will be automatically debited from your account to www.mpnrconline.org processes the payments through an online gateway system which enables safe and secure transaction. Bank is not responsible for any inconvenience caused or non-receipt of the payment.</p>
            <ul>
                <li>Bank of India</li>
                <li>Citibank</li>
                <li>HDFC Bank</li>
                <li>ICICI Bank</li>
                <li>Indusind Bank</li>
                <li>Kotak Bank</li>
                <li>Punjab National Bank</li>
                <li> AXIS Bank</li>
                <li>SBI Bank</li>
            </ul>
            </>
            <h3>
            2.Transaction Confirmation
            </h3>
            <p>
            You should not take any action based on information on the Website until you have received a confirmation of your transaction. In case of confirmations to be received by email. If you do not receive a confirmation of your PAYMENT or transaction within the stipulated time period, first look into your "spam" or "junk" folder to verify that it has not been misdirected, and if still not found, please contact at support@mpnrconline.org.
            </p>
            <h3>3. Agreement Between You and mpnrconline.org</h3>
            <p>The following terms and conditions (the "Terms and Conditions") apply to all visitors to or users of this Web site. By accessing this Web site, the user acknowledges acceptance of these Terms and Conditions. In the case of any violation of these Terms and Conditions, A2A Services and its subsidiaries, (collectively, "mpnrconline.org"), reserve the right to seek all remedies available by law and in equity for such violations.</p>
            <h3>4. General Use Provisions and Copyright</h3>
            <p>All materials provided on this Web site, including but not limited to all text, logos, designs, graphics, images, sounds, information, software, documents, products and services (collectively, the "Materials''), and the selection, arrangement and display thereof, are the copyrighted works of mpnrconline.org and/or its vendors or suppliers. All Materials herein and all mpnrconline.org software are proprietary to mpnrconline.org and protected by worldwide copyright and other intellectual property laws. Except as stated herein, none of the Materials may be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means, without the prior express written permission of mpnrconline.org.</p>
            <h3>5. Limited License</h3>
            <p>mpnrconline.org hereby authorizes you to view, copy, download and print the Materials available on this Web site, provided that: (1) if you are a potential or actual renter, the Materials are used solely for personal, non-commercial purposes and if you are a property owner/manager, the Materials are used only for the purposes of using the services expressly offered by mpnrconline.org; (2) the Materials are not modified or redistributed; and (3) all copyright, trademark, service mark and other proprietary notices are reproduced as they appear in any such Materials. Except as expressly provided above, nothing contained herein shall be construed as conferring, by implication, estoppel or otherwise, any license or right under any patent, trademark or copyright of mpnrconline.org.</p>
            <h3>6. Devices</h3>
            <p>You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or copy our Web pages or the content contained herein without our prior expressed written permission. You agree that you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the mpnrconline.org site or any listing, offer or transaction being conducted on our site. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on our infrastructure.</p>
            <h3>7. No Unlawful or Prohibited Use</h3>
            <p>As a condition of your use of this Web site, you warrant to mpnrconline.org that you will comply with all applicable laws, statutes, ordinances and regulations regarding your use of our service and any related activities. In addition, you warrant that you will not use this Web site in any way prohibited by these terms, conditions and notices.</p>
            <h3>8. Consolidation</h3>
            <p><b>mpnrconline.org</b> does not authorize any of its agents and franchises to consolidate or resell mpnrconline.org products to sub-agents and/or other agents and franchises. If found all such bookings shall be treated as fraudulent and mpnrconline.org has the right to cancel such bookings at its own discretion with no liability for any consequential loss or damage. Any consolidation or reselling of mpnrconline.org products and services by mpnrconline.org agents and franchises requires prior approval and permission of mpnrconline.org</p>
            <h3>9. Links to Third-Party Web Sites</h3>
            <p>This Web site may contain links to Web sites operated by parties other than mpnrconline.org. Such hyperlinks are provided for reference only.mpnrconline.org does not control such Web sites and is not responsible for their contents. mpnrconline.org's inclusion of hyperlinks to such Web sites does not imply any endorsement of the material on such Web sites or any association with their operators. If you decide to access any of the third party sites linked to this Web site, you do so entirely at your own risk.</p>
            <h3>10. Limitation on Liability</h3>
            <p>mpnrconline.org merely provides intermediary services in order to facilitate highest quality services to you. www.mpnrconline.org is not the last-mile service provider to you and therefore it shall not be or deemed to be responsible for any lack or deficiency of services provided by any person (airline, travel/tour operator or similar agency) you shall engage or hire or appoint pursuant to or resulting from the material available in this website. Neither mpnrconline.org nor any of its directors, employees, agents, vendors or suppliers will be liable for any direct, indirect, special, punitive, consequential or incidental damages including, without limitation, lost profits or revenues, costs of replacement goods, loss or damage to data arising out of the use or inability to use this Web site or any mpnrconline.org product, or damages from the use of or reliance on the information present on this Web site, even if mpnrconline.org has been advised of the possibility of such damages. Cancellation, No Show and Refunds will be as per operator policies.</p>
            <h3>11. Modification of the Web Site</h3>
            <p>mpnrconline.org (and/or its suppliers) reserves the right in its sole discretion to improve, modify or remove any information or content appearing on the Web site. mpnrconline.org may discontinue or revise any or all aspects of the Web site in its sole discretion and without prior notice.</p>
            <h3>12. Modification of These Terms of Use</h3>
            <h3>Cookies</h3>
            <p>mpnrconline.orgreserves the right to change the terms, conditions, and notices under which this Web site is offered at any time. Modification of this contract will be deemed effective upon publication on the Web site with respect to any actions, communications or transactions occurring after said date. It is your responsibility to check terms and conditions of this Agreement at the time of each use.</p>
            <h3>13. Severability</h3>
            <p>If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.  </p>
            <h3>14. General</h3>
            <p>This agreement is governed by the laws of the India, without regard to its conflicts of laws principles. You hereby consent to the exclusive jurisdiction and venue of courts in Ajmer, India, in all disputes arising out of or relating to the use of this Web site. Use of this Web site is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.</p>
        </div>
      
    </div>
  )
}

export default Conditions
