import './App.css';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import AboutUs from './Components/Pages/About';
import Conditions from './Components/Pages/Conditions';
import Contact from './Components/Pages/Contact'
import Home from './Components/Pages/Home';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import Refund from './Components/Pages/Refund';
import Navbar from './Components/Navbar/Navbar';


function App() {
  return (
    <div className="App">
        <Navbar/>
      <Routes>
      <Route exact path='/' element={<Home/>} />
      <Route  path='/aboutus' element={<AboutUs/>} />
      <Route  path='/termsconditions' element={ <Conditions/>} />
      <Route  path='/privacypolicy' element={<PrivacyPolicy/>} />
      <Route  path='/refund' element={<Refund/> } />
      <Route  path='/contactus' element={<Contact/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;