import React from 'react';

import '../../Styles/footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      
      <p> Copyright © Binary Systems.  All rights
            reserved.
        </p>
    </div>
  );
}

export default Footer;
