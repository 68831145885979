  import React from 'react'
  import '../../Styles/pages.css'
  import { Helmet } from 'react-helmet';
  const Refund = () => {
    return (
      <div className='refund'>
        <Helmet>
                <title>Refund and Cancellation - MP Nurses Registration Council</title>
                <meta name="description" content="Read the Refund and Cancellation policy of MP Nurses Registration Council, including guidelines for refunds and unauthorized requests." />
                <meta name="keywords" content="Refund Policy, Cancellation Policy, MPNRC, refund guidelines, payment" />
            </Helmet>
          <h2>Refund and Cancellation</h2>
          <h3>Effective Date: 1st October 2022</h3>
          <p>
          There is no refund, once the payment is done by you, you must follow the following guidelines. If you have wrongly submitted the form then you can contact the department but there is no refund once the payment is made. All returns will be pre-authorized by MPNRC. Refunds will not be made towards unauthorized requests. For any other information you must contact MPNRC For Further assistance.
          </p>
        
      </div>
    )
  }

  export default Refund
